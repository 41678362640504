@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,900|Overpass+Mono:400,700&display=swap);
*::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    background: #f3f3f3;
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(22, 22, 22, 0.3);
}

*::-webkit-scrollbar-thumb {
    background-color: #ccc;
    box-shadow: none;
    border-radius: 0px;
    border: none;
}

body {
    margin: 0;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: calc(10px + 1vmin);
    line-height: 1;
    color: white;
}

.App {
    background-color: #edf0f2;
    min-height: 100vh;
}

.App * {
    outline: none !important;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

header {
    height: 48px;
    background: #2979FF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
    position: relative;
    z-index: 10;
}

header a {
    color: #fff;
}

header h1 {
    font-size: 0.9em;
    margin: 0;
    flex: 1 1 auto;
    font-weight: 700;
    letter-spacing: 1px;
}

.warning, .info {
    background: #ffa;
    color: #333;
    padding: 10px 20px;
    font-size: 0.9em;
    font-weight: 700;
    display: table;
    line-height: 1.4;
    -webkit-animation: FadeIn 0.4s;
            animation: FadeIn 0.4s;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.info {
    background: #d8efff;
    font-weight: 400;
}

.warning u {
    text-decoration: none;
    border-bottom: 2px solid #333;
    cursor: pointer;
}

.App select {
    padding: 5px 10px;
    font-size: 1em;
    border-radius: 4px;
}

.App select, .App input {
    font-family: "Open Sans", sans-serif !important;
}

.App button {
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #2979FF;
    border: 2px solid #2979FF;
    color: #fff;
    padding: 9px 22px !important;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.App button:hover {
    background-color: #1e5ac1 !important;
    border-color: #007eaf !important;
}

@-webkit-keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes Rotate {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes Rotate {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@-webkit-keyframes slideInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-200%, 0, 0);
                transform: translate3d(-200%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-200%, 0, 0);
                transform: translate3d(-200%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
    }
}
